<template>
  <PdfComponent v-if="isPdfPage" v-bind="props" />
  <WebComponent v-else v-bind="props" />
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import type { ExpanderElement } from '@autobid/strapi-integration/typescript/strapi/elements/Expander'
import PdfComponent from './CardColumnsGroupPdf.vue'
import WebComponent from './CardColumnsGroupApp.vue'

interface Data {
  cards: CardType[]
  expander?: ExpanderElement
}

interface Props {
  data: Data
}

const props = defineProps<Props>()

const isPdfPage = inject('isPdfPage', false)
</script>
